var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.playerIsShowing && _vm.beatPlaying
    ? _c("div", { staticClass: "player" }, [
        _c("div", { staticClass: "player__details" }, [
          _c("img", {
            staticClass: "player__trackImage",
            attrs: { src: _vm.beatImageOrProducerLogo(_vm.beat) },
          }),
          _c(
            "div",
            { staticClass: "player__trackTitleWrapper" },
            [
              _c(
                "div",
                { staticClass: "player__trackTitle" },
                [
                  _c("p", { attrs: { title: _vm.beat.name } }, [
                    _vm._v(_vm._s(_vm.beat.name)),
                  ]),
                  _c("icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.beat.sampled,
                        expression: "beat.sampled",
                      },
                    ],
                    staticClass: "player__iconCopyright",
                    attrs: { name: "copyright" },
                  }),
                  _vm.showLikeOptions
                    ? _c("icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Like Beat",
                            expression: "'Like Beat'",
                          },
                        ],
                        staticClass: "player__likeIcon",
                        attrs: { name: _vm.beatIsLiked ? "heart" : "heart-o" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggleLike(_vm.beat)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "player__trackAuthor player__trackAuthor--isLink",
                  attrs: {
                    to: {
                      name: "Producer",
                      params: { slug: _vm.beat.producer.slug },
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.beat.producer.display_name))]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "player__controls" }, [
          _c(
            "div",
            { staticClass: "player__controlsRow" },
            [
              _c("icon", {
                staticClass: "player__iconPrev",
                attrs: { name: "prev" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.playPrevious.apply(null, arguments)
                  },
                },
              }),
              _c("icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.audioLoading,
                    expression: "!audioLoading",
                  },
                ],
                staticClass: "player__iconPlayPause",
                attrs: { name: _vm.isAudioPaused ? "play" : "pause" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.TOGGLE_PLAY_STATUS.apply(null, arguments)
                  },
                },
              }),
              _c("the-player-loader", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.audioLoading,
                    expression: "audioLoading",
                  },
                ],
                staticClass: "player__iconLoad",
                nativeOn: {
                  click: function ($event) {
                    return _vm.TOGGLE_PLAY_STATUS.apply(null, arguments)
                  },
                },
              }),
              _c("icon", {
                staticClass: "player__iconNext",
                attrs: { name: "next" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.playNext.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "player__controlsRow player__controlsRow--topOnMobile",
            },
            [
              _c("div", { staticClass: "player__timeDisplay" }, [
                _vm._v(_vm._s(_vm._f("time")(_vm.currentPosition))),
              ]),
              _c(
                "div",
                {
                  staticClass: "player__timeBar",
                  on: {
                    mousedown: _vm.startDrag,
                    mouseup: function ($event) {
                      return _vm.stopDrag($event)
                    },
                    mousemove: function ($event) {
                      return _vm.doDrag($event)
                    },
                    touchmove: function ($event) {
                      return _vm.setNewCurrentPosition($event, true)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "player__slider",
                      attrs: {
                        role: "slider",
                        "aria-valuemin": "0",
                        "aria-valuenow": _vm.currentPosition,
                        "aria-valuemax": _vm.trackTotalDuration,
                        "aria-orientation": "horizontal",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "player__currentPosition",
                        style: _vm.playerBarStyling,
                      }),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "player__timeDisplay" }, [
                _vm._v(_vm._s(_vm._f("time")(_vm.trackTotalDuration))),
              ]),
            ]
          ),
        ]),
        _c("ul", { staticClass: "player__beatActions" }, [
          _c(
            "li",
            { staticClass: "player__beatAction" },
            [
              _c("icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Demo Download",
                    expression: "'Demo Download'",
                  },
                ],
                class: { isDisabled: !_vm.beat.demo_download_allowed },
                attrs: { name: "download" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.demoDownloadBeat(_vm.beat)
                  },
                },
              }),
            ],
            1
          ),
          _c("li", { staticClass: "player__beatAction" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Add To Cart",
                    expression: "'Add To Cart'",
                  },
                ],
                staticClass: "btn btn--white",
                class: { isDisabled: _vm.beatIsFullyBought },
                on: {
                  click: function ($event) {
                    return _vm.SET_ACTIVE_MODAL({
                      modal: _vm.ModalType.LicenseOptionSelection,
                      payload: _vm.beat,
                    })
                  },
                },
              },
              [_vm._v(_vm._s(_vm.beatAlreadyInCart ? "In Cart" : "+ ADD"))]
            ),
          ]),
          _c("li", { staticClass: "player__beatAction" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeMenuDropdown,
                    expression: "closeMenuDropdown",
                  },
                ],
                staticClass: "dropdown",
              },
              [
                _c(
                  "a",
                  { on: { click: _vm.toggleMenuDropdown } },
                  [_c("icon", { attrs: { name: "open-menu" } })],
                  1
                ),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.menuDropDown,
                        expression: "menuDropDown",
                      },
                    ],
                    staticClass:
                      "dropdown__menu dropdown__menu--right dropdown__menu--bottom",
                  },
                  [
                    _c("li", { staticClass: "dropdown__item" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.SET_ACTIVE_MODAL({
                                modal: _vm.ModalType.Share,
                                payload: _vm.beat,
                              })
                            },
                          },
                        },
                        [_vm._v("Share")]
                      ),
                    ]),
                    _c("hr", { staticClass: "dropdown__divider" }),
                    _vm.showLikeOptions
                      ? _c("li", { staticClass: "dropdown__item" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggleLike(_vm.beat)
                                },
                              },
                            },
                            [_vm._v("Like Beat")]
                          ),
                        ])
                      : _vm._e(),
                    _c("li", { staticClass: "dropdown__item" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.demoDownloadBeat(_vm.beat)
                            },
                          },
                        },
                        [_vm._v("Demo Download")]
                      ),
                    ]),
                    _c("li", { staticClass: "dropdown__item" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.SET_ACTIVE_MODAL({
                                modal: _vm.ModalType.LicenseOptionSelection,
                                payload: _vm.beat,
                              })
                            },
                          },
                        },
                        [_vm._v("Add To Cart")]
                      ),
                    ]),
                    _c("hr", { staticClass: "dropdown__divider" }),
                    _c(
                      "li",
                      { staticClass: "dropdown__item" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "Producer",
                                params: { slug: _vm.beat.producer.slug },
                              },
                            },
                          },
                          [_vm._v("Go To Producer")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "dropdown__item" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "ArtistType",
                                params: { slug: _vm.beat.artist_type.slug },
                              },
                            },
                          },
                          [_vm._v("Go To Artist Type")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
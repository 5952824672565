import store from '@/store'
import { Route } from 'vue-router'

/**
 * redirect operations
 */
export function redirect(to: Route, from: Route, next: any) {
    // redirect operations and routes meta checks
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/isAuthenticated']) {
        /*
         * If the user is not authenticated and visits
         * a page that requires authentication, show login
         */
        next({ name: 'Login' })
        // window.location.href = 'https://clickandrap.com/login'
    } else if (to.matched.some(record => record.meta.guest) && store.getters['auth/isAuthenticated']) {
        /*
         * If the user is authenticated and visits
         * an guest page, redirect to the dashboard page
         */
        next({ name: 'Home' })
    }
    next()
}

import { get } from '~/services/http-requests'
import { randomizeList } from '~/utils/randomizeList'

const _BS_ARTIST_TYPE = 'artist_types'

export async function fetchSingleArtistType(artistTypeSlug: IArtistType['slug']): Promise<IArtistType> {
    const [first] = await get({
        baseProperty: _BS_ARTIST_TYPE,
        fullUrl: `${_BS_ARTIST_TYPE}.json?slug=${artistTypeSlug}&fields=id,name,slug,image_url,related_artist_types`
    })
    return first
}

export const fetchArtistTypesWithSearch = (value: string): Promise<IBeat> =>
    get({
        baseProperty: _BS_ARTIST_TYPE,
        fullUrl: `${_BS_ARTIST_TYPE}.json?name__icontains=${value}&fields=id,name,slug,image_url&number_of_beats__gte=1`
    })

export async function fetchAllArtistTypes(randomize: boolean): Promise<IArtistType[]> {
    const minNumberOfBeats = 15
    const data = await get({
        baseProperty: _BS_ARTIST_TYPE,
        fullUrl: `${_BS_ARTIST_TYPE}.json?number_of_beats__gte=${minNumberOfBeats}&fields=id,name,slug,image_url,number_of_beats`
    })
    return randomize ? randomizeList(data) : data
}

export async function fetchAllArtistTypesLightAdmin(): Promise<IArtistTypeLight[]> {
    const artistTypes: IArtistTypeLight[] = await get({ fullUrl: `api/${_BS_ARTIST_TYPE}.json?fields=name,id,slug`, isFreeRequest: true })
    return artistTypes.sort((a, b) => (a.name < b.name ? -1 : 1))
}

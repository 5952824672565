































import Vue from 'vue'
import { Component, Watch, Mixins } from 'vue-property-decorator'

import debounce from 'lodash.debounce'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { convertToGoodQuotes } from '~/utils/convertToGoodQuotes'
import { analyticsSearch } from '~/services/analytics'
import { fetchBeatsWithSearch } from '~/api/beats'
import { fetchProducersWithSearch } from '@/api/producers'
import { fetchArtistTypesWithSearch } from '@/api/artist-type'

@Component
export default class NavbarSearch extends Mixins(BeatImageOrProducerLogo) {
    searchKeyword: string = ''
    beats: IBeat[] = []
    producers: IProducer[] = []
    artistTypes: IArtistType[] = []

    get searchResult() {
        return {
            beats: this.beats,
            producers: this.producers,
            artistTypes: this.artistTypes
        }
    }

    makeCall = debounce(async function(value: string) {
        // if use is on producer page, filter only by that producer's beats
        // @ts-ignore
        const routeIsProducer = this.$route.name === 'Producer'
        // @ts-ignore
        this.beats = routeIsProducer ? await fetchBeatsWithSearch(value, this.$route.params.slug) : await fetchBeatsWithSearch(value)
        // @ts-ignore
        this.artistTypes = await fetchArtistTypesWithSearch(value)
        // if user is on the producer profile, don't search for other producers
        if (!routeIsProducer) {
            // @ts-ignore
            this.producers = await fetchProducersWithSearch(value)
        }
        analyticsSearch(value)
    }, 500)

    clearSearch() {
        this.searchKeyword = ''
    }

    goToProducer(slug: string) {
        this.$emit('closeMenu')
        Vue.router.push({ name: 'Producer', params: { slug } })
        this.clearSearch()
    }

    goToArtistType(slug: string) {
        this.$emit('closeMenu')
        Vue.router.push({ name: 'ArtistType', params: { slug } })
        this.clearSearch()
    }

    togglePlayStatus(beat: IBeat) {
        this.$emit('closeMenu')
        // create a playlist array of 1 beat, and play that beat
        this.$store.dispatch('player/togglePlayStatus', { beats: [beat], index: 0 })
        this.clearSearch()
    }

    @Watch('searchKeyword')
    onSearchKeywordChange(newValue: string) {
        if (newValue.length >= 1) this.makeCall(convertToGoodQuotes(newValue))
    }
}

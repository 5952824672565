var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fixedBottomElements" },
    [
      _c("the-back-to-top"),
      _c("the-player"),
      _vm.showDealAlertBar && _vm.userActiveDeals
        ? _c("div", { staticClass: "dealAlert" }, [
            _c("p", {
              staticClass: "dealAlert__text",
              domProps: { innerHTML: _vm._s(_vm.userActiveDeals) },
            }),
            _c(
              "a",
              {
                staticClass: "dealAlert__close",
                on: {
                  click: function ($event) {
                    _vm.showDealAlertBar = false
                  },
                },
              },
              [_c("icon", { attrs: { name: "close" } })],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("modal-manager"),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("router-view", { attrs: { name: "navbar" } })],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("router-view", { class: _vm.blurBackground })],
        1
      ),
      _c("router-view", {
        class: _vm.blurBackground,
        attrs: { name: "footer" },
      }),
      _c("the-fixed-bottom-elements"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
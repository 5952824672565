/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'browse-purchase': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.322 1a3.185 3.185 0 013.1 2.508l3.333.78a.372.372 0 01.278.448l-.261 1.111h.635c.105 0 .203.044.273.12l.046.061 1.12 1.865a.373.373 0 01.052.173l-.004-.035.002.015.002.02.746 14.542a.374.374 0 01-.373.392H3.373A.374.374 0 013 22.608l.746-14.542a.38.38 0 01.053-.173l-.027.054a.371.371 0 01.018-.038l.009-.016 1.118-1.865a.372.372 0 01.32-.18l.267-.001-.302-2.56a.373.373 0 01.253-.398l.074-.016 4.19-.495a.374.374 0 01.042-.076A3.181 3.181 0 0112.322 1zm7.85 7.458h-15.7l-.707 13.796H20.88L20.17 8.458zm-5.053.745a1.12 1.12 0 01.373 2.174v1.369a3.173 3.173 0 01-3.17 3.17 3.173 3.173 0 01-3.17-3.17v-1.37a1.12 1.12 0 111.492-1.055c0 .487-.311.901-.745 1.056v1.369a2.426 2.426 0 002.423 2.423 2.427 2.427 0 002.424-2.423l-.001-1.37a1.12 1.12 0 01.374-2.173zm-5.594.746a.373.373 0 00-.322.56.371.371 0 01.645-.001.373.373 0 00-.322-.559zm5.594 0a.373.373 0 00-.337.534.376.376 0 01.308-.161h.029c.137 0 .258.075.322.186a.373.373 0 00-.322-.559zM5.592 6.592l-.144.001-.67 1.119.947-.001-.133-1.119zm13.604.001l-.598-.001-.262 1.119h1.53l-.67-1.118zM11.483 2.92l-5.496.65.489 4.141H8.29l-.294-1.227A.373.373 0 018.2 6.06l.071-.025 3.48-.838-.268-2.277zm2.607 2.482l-5.282 1.27.249 1.039h5.588l-.555-2.309zm-.225-1.492l-.194.826.607-.146a.376.376 0 01.216.013l.066.031a.374.374 0 01.168.231l.684 2.846h2.158l.652-2.782-4.357-1.019zm-1.543-2.164c-.536 0-1.052.18-1.47.498l.913-.108a.373.373 0 01.414.326l.303 2.56.378-.091.363-1.554a.373.373 0 01.108-.187l.059-.045a.374.374 0 01.281-.046l.919.215a2.438 2.438 0 00-2.268-1.568z" _fill="#000" fill-rule="nonzero"/>'
  }
})

/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'youtube': {
    width: 23,
    height: 16,
    viewBox: '0 0 23 16',
    data: '<path pid="0" d="M17.483.193h-12.9A4.583 4.583 0 000 4.776v6.448a4.583 4.583 0 004.583 4.583h12.9a4.583 4.583 0 004.583-4.583V4.776A4.583 4.583 0 0017.483.193zm-3.099 8.12L8.35 11.193a.242.242 0 01-.346-.219V5.037c0-.18.19-.297.351-.216L14.39 7.88c.18.09.177.348-.005.435z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})

/* eslint-disable */
import './add-to-cart-o'
import './alert-off'
import './alert-on'
import './american'
import './arrow-down'
import './arrow-left'
import './arrow-right'
import './browse-arrow1'
import './browse-arrow2'
import './browse-music'
import './browse-produce'
import './browse-purchase'
import './cart-empty'
import './cart'
import './chart-down'
import './chart-new'
import './chart-same'
import './chart-up'
import './check'
import './checkbox-custom'
import './close'
import './cloud-download'
import './copyright'
import './credit-card-o'
import './credit-card'
import './deal-o'
import './deal'
import './diners'
import './discover'
import './document-o'
import './download-o'
import './download'
import './email-help-o'
import './error'
import './facebook'
import './google'
import './hamburger'
import './heart-o'
import './heart'
import './info'
import './instagram'
import './jcb'
import './license'
import './lock'
import './logo-icon'
import './logo'
import './master'
import './microphone-o'
import './mp3'
import './negotiation'
import './next'
import './open-menu'
import './pause-o'
import './pause'
import './paypal'
import './piano-o'
import './play-o'
import './play'
import './prev'
import './profile-star'
import './profile'
import './search'
import './share'
import './shopping-cart-o'
import './shopping-cart'
import './speaker'
import './star'
import './stems'
import './success'
import './timer'
import './twitter'
import './user-alt'
import './visa'
import './wav'
import './youtube'

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar", class: { "navbar--sticky": _vm.isStickyNavbar } },
    [
      _c(
        "ul",
        {
          staticClass: "navbar--mobile navbar__items",
          class: { "navbar--sticky": _vm.isStickyNavbar },
        },
        [
          _c(
            "li",
            { staticClass: "navbar__item navbar__logo" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home" } } },
                [
                  _c("icon", {
                    staticClass: "navbar__logo",
                    attrs: { name: "logo", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass:
                "navbar__item navbar__search navbar__search navbar__item--right",
            },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toggleMobileMenu("search")
                    },
                  },
                },
                [_c("icon", { attrs: { name: "search" } })],
                1
              ),
            ]
          ),
          _c(
            "li",
            { staticClass: "navbar__item" },
            [
              _c(
                "router-link",
                {
                  staticClass: "navbar__cart",
                  attrs: { to: { name: "Cart" } },
                },
                [
                  _c("icon", {
                    staticClass: "navbar__cart-icon--small",
                    attrs: { name: "cart" },
                  }),
                  _c("div", { staticClass: "navbar__cart-count" }, [
                    _c("span", [
                      _vm._v(_vm._s(!_vm.cart ? 0 : _vm.cart.length)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("li", { staticClass: "navbar__item" }, [
            _c(
              "a",
              { on: { click: _vm.toggleMobileMenu } },
              [_c("icon", { attrs: { name: "hamburger" } })],
              1
            ),
          ]),
        ]
      ),
      _vm.showMobileMenu
        ? _c(
            "a",
            {
              staticClass: "navbar__close",
              on: { click: _vm.toggleMobileMenu },
            },
            [_c("icon", { attrs: { name: "close" } })],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        {
          staticClass: "navbar--desktop navbar__items",
          class: { "navbar--desktop--open": _vm.showMobileMenu },
        },
        [
          _c(
            "li",
            { staticClass: "navbar__item navbar__logo" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home" } } },
                [
                  _c("icon", {
                    staticClass: "navbar__logo",
                    attrs: { name: "logo", color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Browse" } } }, [
                _vm._v("Browse"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Genres" } } }, [
                _vm._v("Genres"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Playlists" } } }, [
                _vm._v("Playlists"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Trending" } } }, [
                _vm._v("Trending"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Favourites" } } }, [
                _vm._v("Favorites"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__item--link",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c("router-link", { attrs: { to: { name: "Support" } } }, [
                _vm._v("Help"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            {
              staticClass: "navbar__item navbar__search navbar__item--right",
              class: { "navbar__search--hide": !_vm.isSearch },
            },
            [_c("navbar-search", { on: { closeMenu: _vm.toggleMobileMenu } })],
            1
          ),
          _c(
            "li",
            { staticClass: "navbar__item navbar__cart" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Cart" } } },
                [
                  _vm._v("Cart"),
                  _c("icon", {
                    staticClass: "navbar__cart-icon",
                    attrs: { name: "cart" },
                  }),
                  _c("div", { staticClass: "navbar__cart-count" }, [
                    _c("span", [
                      _vm._v(_vm._s(!_vm.cart ? 0 : _vm.cart.length)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isAuthenticated
            ? _c(
                "li",
                {
                  staticClass: "navbar__item navbar__item--btn",
                  class: { "navbar__item--hide": _vm.isSearch },
                  on: { click: _vm.toggleMobileMenu },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn--white btn--text",
                      attrs: {
                        to: {
                          name: "Login",
                          query: { redirect: this.$route.fullPath },
                        },
                      },
                    },
                    [_vm._v("Login")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn--white",
                      attrs: { to: { name: "Signup" } },
                    },
                    [_vm._v("SIGNUP")]
                  ),
                ],
                1
              )
            : _c("navbar-user", {
                class: { "navbar__item--hide": _vm.isSearch },
                on: { closeMenu: _vm.toggleMobileMenu },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
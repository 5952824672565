/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'browse-arrow1': {
    width: 82,
    height: 82,
    viewBox: '0 0 82 82',
    data: '<path pid="0" d="M11 21l.15.005A2 2 0 0113 23l-.001 23.387h53.747l-6.973-6.973a2 2 0 01-.117-2.701l.117-.127a2 2 0 012.701-.117l.127.117L72.94 46.924a2 2 0 01.117 2.702l-.117.127L62.6 60.09a2 2 0 01-2.945-2.7l.117-.128 6.875-6.876H11l-.15-.005A2 2 0 019 48.387V23l.005-.15A2 2 0 0111 21z" _fill="#979797" fill-rule="nonzero"/>'
  }
})

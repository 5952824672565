import Vue from 'vue'
import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'

export const BaseStore = namespace('base')

export default {
    namespaced: true,
    actions: {
        async onAppLoad({ rootState, commit, dispatch }: VuexActionData<any>) {
            if (!rootState.auth.token_id) return
            Vue.$axios.defaults.headers.common.Authorization = `Token ${rootState.auth.token_id}`
            try {
                // the token could be expired, so first fetch the user infos
                await dispatch('user/fetchOwnProfile', null, { root: true })
                // and if the responseStatus is successful, we proceed and fetch the other data
                await dispatch('cart/fetchCartItems', null, { root: true })
                await dispatch('user/fetchFollowedProducers', null, { root: true })
                // we do it on app load becuase we need the data to adjust the add to cart btn and license options available
                await dispatch('user/fetchBoughtBeats', null, { root: true })
                await dispatch('user/fetchFollowedPlaylists', null, { root: true })
                await dispatch('user/fetchLikedBeats', null, { root: true })
            } catch (err) {
                commit('auth/LOGOUT', null, { root: true })
                dispatch('user/resetEverythingOfUser', null, { root: true })
            }
        }
    }
}

import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=79d3d815&lang=pug&"
import script from "./Index.vue?vue&type=script&lang=ts&"
export * from "./Index.vue?vue&type=script&lang=ts&"
import style0 from "./Index.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/clickandrap_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79d3d815')) {
      api.createRecord('79d3d815', component.options)
    } else {
      api.reload('79d3d815', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=79d3d815&lang=pug&", function () {
      api.rerender('79d3d815', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/marketplace/components/modals/Index.vue"
export default component.exports
import Vue from 'vue'
import Vuex from 'vuex'

// modules
import base from '@/store/base'
import auth from '~/store/auth'
import beat from '~/store/beat'
import cart from '~/store/cart'
import user from '@/store/user'
import player from '~/store/player'
import modal from '~/store/modal'

// caches
import { vuexToken, vuexCart, vuexUser } from '~/store/vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [vuexCart, vuexUser, vuexToken],
    modules: { base, auth, user, beat, cart, modal, player }
})

import { get, update, post, remove } from '~/services/http-requests'

const _BS_FOLLOWED_PRODUCERS = 'followed_producers'

export interface IFollowedProducerParams {
    producerId: IProducer['id']
    shouldReceiveEmail?: boolean
}

/*
 **** GET ****
 */
function fetchFollowedProducers(): Promise<IFollowedProducer[]> {
    return get({ baseProperty: _BS_FOLLOWED_PRODUCERS })
}

/*
 **** POST ****
 */
function createFollowedProducer({ producerId, shouldReceiveEmail = false }: IFollowedProducerParams) {
    return post({ baseProperty: _BS_FOLLOWED_PRODUCERS, dispatchParams: { producer_id: producerId, should_receive_email: shouldReceiveEmail } })
}

/*
 **** PATCH ****
 */
function updateFollowedProducer({ followedProducerId, shouldReceiveEmail }: { followedProducerId: IFollowedProducer['id']; shouldReceiveEmail: boolean }) {
    return update({
        baseProperty: _BS_FOLLOWED_PRODUCERS,
        fullUrl: `${_BS_FOLLOWED_PRODUCERS}/${followedProducerId}/`,
        dispatchParams: { should_receive_email: shouldReceiveEmail }
    })
}

/*
 **** DELETE ****
 */
function removeFollowedProducer(followedProducerId: IFollowedProducer['id']) {
    return remove({ baseProperty: _BS_FOLLOWED_PRODUCERS, fullUrl: `${_BS_FOLLOWED_PRODUCERS}/${followedProducerId}/` })
}

export { fetchFollowedProducers, createFollowedProducer, updateFollowedProducer, removeFollowedProducer }

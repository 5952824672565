





















import { Component, Mixins } from 'vue-property-decorator'

import { AuthStore } from '~/store/auth'
import { DynamicClassesOnShow } from '~/mixins/dynamic-classes-on-show'

@Component
export default class Footer extends Mixins(DynamicClassesOnShow) {
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
}

import { get, post, remove } from '~/services/http-requests'

const _BS_LIKED_BEATS = 'liked_beats'

/*
 **** GET ****
 */
function fetchLikedBeats(): Promise<ILikedBeat[]> {
    return get({ baseProperty: _BS_LIKED_BEATS })
}

/*
 **** POST ****
 */
function createLikedBeat(beatId: IBeat['id']) {
    return post({ baseProperty: _BS_LIKED_BEATS, dispatchParams: { beat_id: beatId } })
}

/*
 **** DELETE ****
 */
function removeLikedBeat(likedBeatId: ILikedBeat['id']) {
    return remove({ baseProperty: _BS_LIKED_BEATS, fullUrl: `${_BS_LIKED_BEATS}/${likedBeatId}/` })
}

export { fetchLikedBeats, createLikedBeat, removeLikedBeat }

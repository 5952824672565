/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'twitter': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M20.096 2.036a7.88 7.88 0 01-2.266.622A3.962 3.962 0 0019.565.476a7.955 7.955 0 01-2.508.958 3.946 3.946 0 00-6.724 3.598A11.203 11.203 0 012.201.909a3.916 3.916 0 00-.534 1.983c0 1.37.697 2.577 1.755 3.284a3.946 3.946 0 01-1.787-.495v.049A3.95 3.95 0 004.8 9.6c-.331.088-.68.137-1.04.137-.255 0-.501-.025-.743-.073a3.948 3.948 0 003.685 2.74 7.917 7.917 0 01-4.9 1.687c-.319 0-.632-.02-.941-.054a11.154 11.154 0 006.048 1.775c7.259 0 11.226-6.012 11.226-11.226l-.013-.511a7.88 7.88 0 001.974-2.039z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})

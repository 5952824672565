var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clearSearch,
          expression: "clearSearch",
        },
      ],
      staticClass: "dropdown searchDropdown",
    },
    [
      _c(
        "div",
        { staticClass: "formGroup" },
        [
          _c("icon", {
            staticClass: "formInput__icon",
            attrs: { name: "search" },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.searchKeyword,
                expression: "searchKeyword",
                modifiers: { trim: true },
              },
            ],
            staticClass: "formInput formInput--withIcon searchDropdown__input",
            attrs: { type: "text", placeholder: "Search" },
            domProps: { value: _vm.searchKeyword },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchKeyword = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ],
        1
      ),
      _vm.searchKeyword && _vm.searchKeyword.length >= 2
        ? _c("ul", { staticClass: "dropdown__menu" }, [
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchResult.producers.length > 0,
                    expression: "searchResult.producers.length > 0",
                  },
                ],
              },
              [
                _c("h4", { staticClass: "dropdown__header" }, [
                  _vm._v("Producers"),
                ]),
                _vm._l(_vm.searchResult.producers, function (producer) {
                  return _c(
                    "div",
                    {
                      staticClass: "dropdown__item",
                      on: {
                        click: function ($event) {
                          return _vm.goToProducer(producer.slug)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "dropdown__image h-rounded",
                        attrs: { src: producer.image_logo_url },
                      }),
                      _c("p", [_vm._v(_vm._s(producer.display_name))]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.artistTypes.length > 0,
                    expression: "artistTypes.length > 0",
                  },
                ],
              },
              [
                _c("h4", { staticClass: "dropdown__header" }, [
                  _vm._v("Artist Types"),
                ]),
                _vm._l(_vm.artistTypes, function (artist) {
                  return _c(
                    "div",
                    {
                      staticClass: "dropdown__item",
                      on: {
                        click: function ($event) {
                          return _vm.goToArtistType(artist.slug)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "dropdown__image",
                        attrs: {
                          src:
                            artist.image_url == ""
                              ? "./img/artist-type-default.jpg"
                              : artist.image_url,
                        },
                      }),
                      _c("p", [_vm._v(_vm._s(artist.name))]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchResult.beats.length > 0,
                    expression: "searchResult.beats.length > 0",
                  },
                ],
              },
              [
                _c("h4", { staticClass: "dropdown__header" }, [
                  _vm._v("Beats"),
                ]),
                _vm._l(_vm.searchResult.beats, function (beat, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "dropdown__item",
                      on: {
                        click: function ($event) {
                          return _vm.togglePlayStatus(beat, index)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "dropdown__image",
                        attrs: { src: _vm.beatImageOrProducerLogo(beat) },
                      }),
                      _c("div", { staticClass: "cutOverflow" }, [
                        _c("p", [_vm._v(_vm._s(beat.name))]),
                        _c("p", { staticClass: "p p--grey textSizeXs" }, [
                          _vm._v(_vm._s(beat.producer.display_name)),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.searchResult.beats.length === 0 &&
                      _vm.searchResult.artistTypes.length === 0 &&
                      _vm.searchResult.producers.length === 0,
                    expression:
                      "searchResult.beats.length === 0 && searchResult.artistTypes.length === 0 && searchResult.producers.length === 0",
                  },
                ],
                staticClass: "searchDropdown__noResults",
              },
              [
                _c("h4", { staticClass: "dropdown__header" }, [
                  _vm._v("No Results Found"),
                ]),
                _c(
                  "p",
                  { staticClass: "p p--grey" },
                  [
                    _vm._v(
                      "The producer, artist-type or beat you are looking for was not found, please check your spelling, try a different keyword or "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link",
                        attrs: { to: { name: "Support" } },
                      },
                      [_vm._v("contact support")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }












import { Vue, Component } from 'vue-property-decorator'

import { ModalType } from '~/store/modal'
import ModalManager from '@/components/modals/Index.vue'
import TheFixedBottomElements from '~/components/TheFixedBottomElements.vue'

@Component({ components: { ModalManager, TheFixedBottomElements } })
export default class App extends Vue {
    get blurBackground() {
        if (this.$store.state.modal.activeModal && this.$store.state.modal.activeModal.modal === ModalType.LicenseOptionSelection) return 'h-blur'
    }

    mounted() {
        this.$store.dispatch('base/onAppLoad')
    }
}

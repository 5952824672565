import { get, post, remove } from '~/services/http-requests'

const _BS_FOLLOWED_PLAYLIST = 'followed_playlists'

/*
 **** GET ****
 */
function fetchFollowedPlaylists(): Promise<IFollowedPlaylist[]> {
    return get({ baseProperty: _BS_FOLLOWED_PLAYLIST })
}

/*
 **** POST ****
 */
function createFollowedPlaylist(playlistId: IPlaylist['id']) {
    return post({ baseProperty: _BS_FOLLOWED_PLAYLIST, dispatchParams: { playlist_id: playlistId } })
}

/*
 **** DELETE ****
 */
function removeFollowedPlaylist(followedPlaylistId: IFollowedPlaylist['id']) {
    return remove({ baseProperty: _BS_FOLLOWED_PLAYLIST, fullUrl: `${_BS_FOLLOWED_PLAYLIST}/${followedPlaylistId}/` })
}

export { fetchFollowedPlaylists, createFollowedPlaylist, removeFollowedPlaylist }

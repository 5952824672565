/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'facebook': {
    width: 10,
    height: 16,
    viewBox: '0 0 10 16',
    data: '<path pid="0" d="M8.877.248L6.866.245c-2.26 0-3.72 1.498-3.72 3.817v1.76H1.123a.316.316 0 00-.316.316v2.55c0 .175.142.317.316.317h2.023v6.434c0 .175.141.316.316.316h2.639a.316.316 0 00.316-.316V9.005H8.78a.316.316 0 00.317-.317v-2.55a.317.317 0 00-.316-.316H6.417V4.33c0-.717.17-1.081 1.105-1.081h1.355a.316.316 0 00.316-.317V.564a.316.316 0 00-.316-.316z" _fill="#FFF" fill-rule="evenodd"/>'
  }
})

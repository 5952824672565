var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-load" }, [
    _c(
      "svg",
      {
        attrs: {
          id: "L5",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          viewBox: "0 0 100 100",
          "xml:space": "preserve",
        },
      },
      [
        _c("circle", {
          attrs: {
            cx: "50",
            cy: "50",
            r: "46",
            "stroke-width": "6",
            fill: "none",
          },
        }),
        _c(
          "circle",
          { attrs: { stroke: "none", cx: "27", cy: "50", r: "6" } },
          [
            _c("animateTransform", {
              attrs: {
                attributeName: "transform",
                dur: "1s",
                type: "translate",
                values: "0 15 ; 0 -15; 0 15",
                repeatCount: "indefinite",
                begin: "0.1",
              },
            }),
          ],
          1
        ),
        _c(
          "circle",
          { attrs: { stroke: "none", cx: "50", cy: "50", r: "6" } },
          [
            _c("animateTransform", {
              attrs: {
                attributeName: "transform",
                dur: "1s",
                type: "translate",
                values: "0 10 ; 0 -10; 0 10",
                repeatCount: "indefinite",
                begin: "0.2",
              },
            }),
          ],
          1
        ),
        _c(
          "circle",
          { attrs: { stroke: "none", cx: "74", cy: "50", r: "6" } },
          [
            _c("animateTransform", {
              attrs: {
                attributeName: "transform",
                dur: "1s",
                type: "translate",
                values: "0 5 ; 0 -5; 0 5",
                repeatCount: "indefinite",
                begin: "0.3",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
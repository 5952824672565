/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'browse-arrow2': {
    width: 82,
    height: 82,
    viewBox: '0 0 82 82',
    data: '<path pid="0" d="M62.474 21.469l.127.117L72.94 31.924a2 2 0 01.117 2.702l-.117.127L62.6 45.09a2 2 0 01-2.945-2.7l.117-.128 6.924-6.925H12.999L13 58.726a2 2 0 01-1.85 1.994l-.15.006a2 2 0 01-1.995-1.851L9 58.725V33.34a2 2 0 011.85-1.995l.15-.005 55.697-.001-6.924-6.924a2 2 0 01-.117-2.701l.117-.127a2 2 0 012.701-.117z" _fill="#979797" fill-rule="nonzero"/>'
  }
})

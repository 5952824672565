var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modalManager" },
    [
      _vm.activeModal &&
      _vm.activeModal.modal === _vm.ModalType.LicenseOptionSelection &&
      _vm.activeModal.payload
        ? _c("license-options-selection", {
            attrs: { beat: _vm.activeModal.payload },
          })
        : _vm._e(),
      _vm.activeModal &&
      _vm.activeModal.modal === _vm.ModalType.MailingList &&
      _vm.activeModal.payload
        ? _c("producer-mailing-list-subscription", {
            attrs: { producerId: _vm.activeModal.payload },
          })
        : _vm._e(),
      _vm.activeModal &&
      _vm.activeModal.modal === _vm.ModalType.BeatAction &&
      _vm.activeModal.payload
        ? _c("beat-action", { attrs: { beat: _vm.activeModal.payload } })
        : _vm._e(),
      _vm.activeModal && _vm.activeModal.modal === _vm.ModalType.Coupon
        ? _c("coupon")
        : _vm._e(),
      _vm.activeModal &&
      _vm.activeModal.modal === _vm.ModalType.Share &&
      _vm.activeModal.payload
        ? _c("share-beat", { attrs: { beat: _vm.activeModal.payload } })
        : _vm._e(),
      _vm.activeModal && _vm.activeModal.modal === _vm.ModalType.TermsOfService
        ? _c("TermsOfService")
        : _vm._e(),
      _vm.activeModal &&
      (_vm.activeModal.modal === _vm.ModalType.RequireAccountForLike ||
        _vm.activeModal.modal === _vm.ModalType.RequireAccountForFollow)
        ? _c("account-required", {
            attrs: { modalType: _vm.activeModal.modal },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "notifications-container",
          class: { "notifications-container--no-top": _vm.removeTopMargin },
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "slide-fade" } },
            [
              _vm._l(_vm.activeNotifications, function (notification) {
                return [
                  _c("notification-alert", {
                    key: notification.id,
                    attrs: { notification: notification },
                    on: { handleScroll: _vm.handleScroll },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


































import { Vue, Prop, Component, Mixins, Watch } from 'vue-property-decorator'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'

const LicenseOptionsSelection = () => import('~/components/modals/LicenseOptionsSelection.vue')
const BeatAction = () => import('@/components/modals/BeatAction.vue')
const NotificationAlert = () => import('~/components/modals/NotificationAlert.vue')
const Coupon = () => import('~/components/modals/Coupon.vue')
const ShareBeat = () => import('~/components/modals/ShareBeat.vue')
const ProducerMailingListSubscription = () => import('@/components/modals/ProducerMailingListSubscription.vue')
// import DemoDownloadAction from '@/components/modals/DemoDownloadAction.vue'
const AccountRequired = () => import('@/components/modals/AccountRequired.vue')
const TermsOfService = () => import('~/components/modals/TermsOfService.vue')

@Component({
    components: {
        LicenseOptionsSelection,
        NotificationAlert,
        Coupon,
        BeatAction,
        ShareBeat,
        ProducerMailingListSubscription,
        AccountRequired,
        TermsOfService
    }
})
export default class ModalManager extends Vue {
    removeTopMargin = false
    ModalType = ModalType

    @ModalStore.State('activeNotifications') activeNotifications: any
    @ModalStore.State('activeModal') activeModal: IModalParams

    @Watch('activeModal', { deep: true })
    onActiveModalChange(newValue: IModalParams, oldValue: IModalParams) {
        if (newValue.modal !== null) {
            // @ts-ignore
            document.body.parentNode.classList.add('noScroll')
            document.body.classList.add('noScroll')
        } else {
            // @ts-ignore
            document.body.parentNode.classList.remove('noScroll')
            document.body.classList.remove('noScroll')
        }
    }

    handleScroll(event: any) {
        if (event < 25) this.removeTopMargin = false
        else this.removeTopMargin = true
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "footer",
      class: [_vm.playerIsShowing, _vm.dealBarIsShowing],
    },
    [
      !_vm.isAuthenticated && this.$route.name !== "Support"
        ? _c(
            "div",
            { staticClass: "footer__cta" },
            [
              _c("h3", [
                _vm._v(
                  "Get started for free today! Save your favorite beats and get a customized experience."
                ),
              ]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn--white",
                  attrs: { to: { name: "Signup" } },
                },
                [_vm._v("SIGN UP")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr", { staticClass: "footer__divider" }),
      _c(
        "div",
        { staticClass: "footer__main" },
        [
          _c("icon", {
            staticClass: "footer__logo",
            attrs: { name: "logo", color: "white" },
          }),
          _c("div", { staticClass: "footer__sub" }, [
            _vm._v("Give Them Music To Talk About"),
          ]),
          _c("ul", { staticClass: "icons-social" }, [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "instagram",
                  attrs: {
                    href: "https://www.instagram.com/wearethecharts",
                    target: "_blank",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "icon icon-facebook",
                    attrs: { name: "instagram" },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "twitter",
                  attrs: {
                    href: "https://www.twitter.com/wearethecharts",
                    target: "_blank",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "icon icon-twitter",
                    attrs: { name: "twitter" },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "facebook",
                  attrs: {
                    href: "https://www.facebook.com/jointhecharts",
                    target: "_blank",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "icon icon-facebook",
                    attrs: { name: "facebook" },
                  }),
                ],
                1
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "youtube",
                  attrs: {
                    href: "https://www.youtube.com/channel/UCQ3Jq8CdOZQ02cPziUaIyzA",
                    target: "_blank",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "icon icon-youtube",
                    attrs: { name: "youtube" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "footer__pagesLink" },
            [
              _c("router-link", { attrs: { to: { name: "TermsOfService" } } }, [
                _vm._v("Terms Of Service"),
              ]),
              _c("router-link", { attrs: { to: { name: "PrivacyPolicy" } } }, [
                _vm._v("Privacy Policy "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
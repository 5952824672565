/* ============
 * vue-analytics
 * ============
 *
 * Google Analytics plugin for Vue https://github.com/MatteoGabriele/vue-analytics
 *
 */
import Vue from 'vue'
// @ts-ignore
import VueAnalytics from 'vue-analytics'
import router from '@/router'
import { ENV_IS_PRODUCTION } from '~/const/environment'

const googleAnalyticsId = ENV_IS_PRODUCTION ? 'UA-60342382-5' : 'UA-60342382-4'

try {
    Vue.use(VueAnalytics, { id: googleAnalyticsId, router, ecommerce: { enabled: true } })
} catch (error) {
    console.log('Analytics is not enabled in Vue')
    console.log(error)
}

import Navbar from '@/layout/Navbar.vue'

import Footer from '@/layout/Footer.vue'
import { LocaleComponent, castParam } from '~/services/router-helper'

export default [
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'Browse' }
    },
    // admin routes
    {
        path: '/admin-latest-beats',
        name: 'LatestBeats',
        component: () => import(/* webpackChunkName: "LatestBeats" */ '@/views/admin/LatestBeats.vue'),
        meta: { requiresAuth: true }
    },
    // {
    //     path: '/migration',
    //     name: 'Migration',
    //     component: () => import(/* webpackChunkName: "OrderReview" */ '@/views/Migration.vue')
    // },
    // Auth
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '~/views/auth/Login.vue')
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "Signup" */ '~/views/auth/Signup.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '~/views/auth/ForgotPassword.vue')
    },
    {
        path: '/password-reset/:uidb64/:token',
        name: 'PasswordReset',
        component: () => import(/* webpackChunkName: "PasswordReset" */ '~/views/auth/PasswordReset.vue'),
        props: true,
        caseSensitive: true
    },
    {
        path: '/activation/:confirmKey',
        name: 'Activation',
        component: () => import(/* webpackChunkName: "Activation" */ '~/views/auth/Activation.vue'),
        props: true
    },
    {
        path: '/mailinglist-unsubscribe/',
        name: 'UnsubscribeMailingList',
        component: () => import(/* webpackChunkName: "UnsubscribeMailingList" */ '~/views/auth/UnsubscribeMailingList.vue')
    },
    {
        path: '/browse',
        name: 'Browse',
        components: {
            default: () => import(/* webpackChunkName: "Browse" */ '@/views/Browse.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/welcome',
        name: 'Welcome',
        components: {
            default: () => import(/* webpackChunkName: "Welcome" */ '@/views/Welcome.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/trending',
        name: 'Trending',
        components: {
            default: () => import(/* webpackChunkName: "Trending" */ '@/views/Trending.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/genres',
        components: { default: LocaleComponent, navbar: Navbar, footer: Footer },
        children: [
            {
                name: 'Genres',
                path: '',
                components: { default: () => import(/* webpackChunkName: "Genres" */ '@/views/Genres.vue') }
            },
            {
                name: 'Genre',
                path: ':slug',
                components: { default: () => import(/* webpackChunkName: "Genre" */ '@/views/Genre.vue') },
                props: { default: true }
            }
        ]
    },
    {
        path: '/artist-types',
        components: { default: LocaleComponent, navbar: Navbar, footer: Footer },
        children: [
            {
                name: 'ArtistTypes',
                path: '',
                components: { default: () => import(/* webpackChunkName: "ArtistTypes" */ '@/views/ArtistTypes.vue') }
            },
            {
                path: ':slug',
                name: 'ArtistType',
                components: { default: () => import(/* webpackChunkName: "ArtistType" */ '@/views/ArtistType.vue') },
                props: { default: true }
            }
        ]
    },
    {
        path: '/instruments',
        components: { default: LocaleComponent, navbar: Navbar, footer: Footer },
        children: [
            {
                name: 'Instruments',
                path: '',
                components: { default: () => import(/* webpackChunkName: "Instruments" */ '@/views/Instruments.vue') }
            },
            {
                name: 'Instrument',
                path: ':slug',
                components: { default: () => import(/* webpackChunkName: "Instrument" */ '@/views/Instrument.vue') },
                props: { default: true }
            }
        ]
    },
    {
        path: '/playlists',
        components: { default: LocaleComponent, navbar: Navbar, footer: Footer },
        children: [
            {
                name: 'Playlists',
                path: '',
                components: { default: () => import(/* webpackChunkName: "Playlists" */ '@/views/Playlists.vue') }
            },
            {
                name: 'Playlist',
                path: ':id',
                components: { default: () => import(/* webpackChunkName: "Playlist" */ '@/views/Playlist.vue') },
                props: { default: castParam }
            }
        ]
    },
    {
        path: '/favourites',
        name: 'Favourites',
        components: {
            default: () => import(/* webpackChunkName: "Favourites" */ '@/views/Favourites.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/support',
        name: 'Support',
        // route level code-splitting
        // this generates a separate chunk (lazyRoutes.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
            default: () => import(/* webpackChunkName: "HelpCenter" */ '~/views/HelpCenter.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        components: {
            default: () => import(/* webpackChunkName: "Cart" */ '@/views/Cart.vue'),
            navbar: () => import('~/components/CartNavbar.vue')
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            default: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
            navbar: Navbar,
            footer: Footer
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/purchases',
        name: 'Purchases',
        components: {
            default: () => import(/* webpackChunkName: "Purchases" */ '~/views/Purchases.vue'),
            navbar: Navbar,
            footer: Footer
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/producer/:slug',
        name: 'Producer',
        components: {
            default: () => import(/* webpackChunkName: "Producer" */ '@/views/Producer.vue'),
            navbar: Navbar,
            footer: Footer
        },
        props: { default: true }
    },
    {
        path: '/order/:email/:orderId',
        name: 'OrderReview',
        components: {
            default: () => import(/* webpackChunkName: "OrderReview" */ '~/views/OrderReview.vue'),
            navbar: Navbar,
            footer: Footer
        },
        props: { default: castParam }
    },
    {
        path: '/beat/:id',
        name: 'Beat',
        components: {
            default: () => import(/* webpackChunkName: "Beat" */ '@/views/MBeat.vue'),
            navbar: Navbar,
            footer: Footer
        },
        props: { default: castParam }
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        components: {
            default: () => import(/* webpackChunkName: "PrivacyPolicy" */ '~/views/PrivacyPolicy.vue'),
            navbar: Navbar,
            footer: Footer
        }
    },
    {
        path: '/terms-of-service',
        name: 'TermsOfService',
        components: {
            default: () => import(/* webpackChunkName: "TermsOfService" */ '~/views/TermsOfService.vue'),
            navbar: Navbar,
            footer: Footer
        }
    }
]






















































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import NavbarSearch from './NavbarSearch.vue'
import NavbarUser from './NavbarUser.vue'
import { UserStore } from '@/store/user'
import { CartStore } from '~/store/cart'
import { AuthStore } from '~/store/auth'

@Component({ components: { NavbarSearch, NavbarUser } })
export default class Navbar extends Vue {
    isSearch = false
    showMobileMenu = false
    isStickyNavbar = false

    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean
    @UserStore.State('authenticatedUser') authenticatedUser: IUser
    @CartStore.State('cart') cart: ICartItem

    toggleMobileMenu(itemToToggle: string) {
        // if client width is less then 1000 (tablet on mobile TODO test tablet)
        if (this.$el.clientWidth <= 1000) {
            this.showMobileMenu = !this.showMobileMenu
            // add remove class
            if (this.showMobileMenu) document.body.classList.add('noScroll')
            else document.body.classList.remove('noScroll')
            // open / close search & block body scroll if the search is open
            if (itemToToggle === 'search' && this.showMobileMenu === true) this.isSearch = true
            else if (this.isSearch === true) this.isSearch = false
        }
    }

    handleScroll(event: any) {
        window.pageYOffset > 0 ? (this.isStickyNavbar = true) : (this.isStickyNavbar = false)
    }

    created() {
        window.addEventListener('scroll', this.handleScroll)
    }

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
